<template>
  <div>
    <h1>Bingo Validator</h1>
    <h2>Frage Nummer:</h2>
    <div class="input-container">
      <div class="number-container">
        <div class="question-container">
          <div class="button" @click="decreaseQuestionNo">⬅️</div>
          <div id="question_no" @click="enterNumber">{{ questionNo }}</div>
          <div class="button" @click="questionNo++">➡️</div>
        </div>
        <div class="button visible-button" @click="nextRandomNumber">zufällige Zahl</div>
      </div>
      <div>Haben beide dasselbe geantwortet?</div>
      <div class="answer-container">
        <div class="button" @click="setQuestion(true)">✅</div>
        <div class="button" @click="setQuestion(false)">❌</div>
      </div>
    </div>
    <div class="result-container">
      <p><b>Auswahl:</b> {{ formattedChosenNumbers }}</p>
      <p><b>Bingo-Karten:</b> {{ formattedBingos }}</p>
    </div>
    <div class="button visible-button" id="reset-button" @click="reset">Reset</div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import cardData from '@/assets/cards_5x5.txt'

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },

  setup() {
    const cards = []
    const chosenNumbers = ref(new Set())
    const bingos = ref(new Set())

    function initCards() {
      let cardBatches = cardData.split("---\n")

      cardBatches.forEach(cardString => {
        let lines = cardString.split(/\r?\n/)
        let matrixSize = lines[1].split(',').length

        let card = {
          id: Number(lines[0]),
          horizontalRows: [],
          verticalRows: [...new Array(matrixSize)].map(() => new Set()),
          diagonalRows: [new Set(), new Set()]
        }

        for (let i=1; i <= matrixSize; i++) {
          let splitted = lines[i].split(',').map(n => Number(n))
          
          // set horizontal line
          card.horizontalRows.push(new Set(splitted))

          // set vertical lines
          for (let j=0; j < splitted.length; j++) {
            card.verticalRows[j].add(splitted[j])
          }

          // set diagonal lines
          card.diagonalRows[0].add(splitted[i-1])
          card.diagonalRows[1].add(splitted[matrixSize - i])
        }

        cards.push(card)
      })
    }
    initCards()

    function findBingos() {
      if (chosenNumbers.value.size === 0)
        return
      
        bingos.value.clear()
      cards.forEach(card => {
        if (hasCardBingo(card, chosenNumbers.value))
          bingos.value.add(card.id)
      });
    }

    function hasCardBingo(card, numbers) {
      return card.horizontalRows.find(row => row.isSubsetOf(numbers))
        || card.verticalRows.find(row => row.isSubsetOf(numbers))
        || card.diagonalRows.find(row => row.isSubsetOf(numbers))
    }

    watch(chosenNumbers.value, () => findBingos())

    let questionNo = ref(1)
    function decreaseQuestionNo() {
      if (questionNo.value > 1)
        questionNo.value--
    }

    function reset() {
      if (confirm("Reset löscht alle eingegebenen Zahlen!")) {
        questionNo.value = 1
        bingos.value.clear()
        chosenNumbers.value.clear()
      }
    }

    function setQuestion(valid) {
      valid
        ? chosenNumbers.value.add(questionNo.value)
        : chosenNumbers.value.delete(questionNo.value)
    }

    const formattedChosenNumbers = computed(() => {
      return [...chosenNumbers.value]
        .sort((a, b) => a - b )
        .join(', ')
    })

    const formattedBingos = computed(() => {
      let bingoString = [...bingos.value]
        .sort((a, b) => a - b )
        .join(', ')
      return bingoString === '' ? 'keine Bingos :(' : bingoString
    })

    function enterNumber() {
      let newNumber = parseInt(prompt("Neue Frage:"), 10)
      if (newNumber && newNumber > 0 && newNumber < 1000)
        questionNo.value = newNumber
    }

    const possibleQuestionNumbers = new Set(
      [...Array(25).keys()].map(x => x+1)
    )

    function nextRandomNumber() {
      let openNumbers = possibleQuestionNumbers.difference(chosenNumbers.value)
      if (openNumbers.size === 0)
        return

      let random = [...openNumbers][Math.floor(Math.random()*openNumbers.size)]
      questionNo.value = random
    }

    return {
      formattedBingos,
      formattedChosenNumbers,
      questionNo,
      decreaseQuestionNo,
      reset,
      setQuestion,
      enterNumber,
      nextRandomNumber
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.question-container {
  display: flex;
}

.question-container * {
  flex-grow: 1;
  height: 2em;
  line-height: 2em;
  font-size: 5em;
  margin: 5px;
} 

.number-container {
  margin: 5px 0px 25px 0px;
}

.answer-container {
  display: flex;
  justify-content: space-around;
}

.answer-container * {
  flex-grow: 1;
  height: 2.5em;
  font-size: 2em;
  line-height: 2.5em;
  margin: 10px;
  max-width: 3em;
}

.result-container {
  margin: 25px;
}

.button {
  /* background-color: orange; */
  border-radius: 10px;
}

#reset-button {
  margin: 30px 20px 20px 20px;
}

.visible-button {
  height: 2em;
  line-height: 2em;
  background-color: orange;
}
</style>
